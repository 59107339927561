import {
   member_tag
} from "../../js/path";
const beautify = require('js-beautify').html;

export default {
   data() {
      return {
         activeTab: "all",
         table_header: [],
         member_tag_type: [],
         currentPage: 1,
         params: "",
         key: 0,
         filter: null,
         filterOn: [],
         sortBy: "id",
         sortDesc: true,
         pageNo: null,
         fields: [{
               key: "id",
               label: "ID",
               sortable: true,
            },
            {
               key: "name",
               label: "Tag Name",
            },
            {
               key: "color",
               label: "Tag Color",
            },
            {
               key: "is_active",
               label: "Display Tag",
            },
            {
               key: "badge_icon",
               label: "Tag Icon",
            },
            {
               key: "email_html",
               label: "Email Template Exist"
            },
            {
               key: "edit",
            },
            {
               key: "delete",
            }
         ],
         form: {
            name: "",
            color: "",
            is_active: 0,
            email_html: ""
         },
         badge_icon: "",
         badge_icon_url: "",
         edit: {
            badge_icon_url: null
         },
         previewEmail: false
      }
   },
   methods: {
      formatCode() {
         const formattedContent = beautify(this.form.email_html, {
          indent_size: 2, 
        });
        this.form.email_html = formattedContent;
      },
      editorInit(editor) {
        if (editor) {
          editor.container.style.fontSize = '18px';
        } else {
          console.error('Editor instance is not defined.');
        }
      },
      showPreview() {
         this.previewEmail = !this.previewEmail;
      },
      readFile(e, txt) {
         if (txt == "badge_icon") {
           this.badge_icon = e.target.files[0];
           if (this.badge_icon.size > 2 * 1000 * 1000) {
             this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Uploaded File is More than 2MB"
             });
             this.$refs.badge_icon.reset();
             this.badge_icon_url = '';
             this.edit.badge_icon_url = '';
             this.badge_icon = '';
             return false;
           } else {
             this.badge_icon_url = URL.createObjectURL(this.badge_icon);
             this.edit.badge_icon_url = '';
           }
           return true;
         }
      },
      preventEmoji() {
         this.form.name = this.form.name.replace(/[^A-Za-z0-9]/g, "");
      },
      onFiltered(filteredItems) {
         this.totalRows = filteredItems.length;
         this.currentPage = 1;
      },
      // searchFor() {
      //    if (this.filter.length > 1) this.fetchData(this.activeTab);
      //    else if (this.filter.length == 0) this.fetchData(this.activeTab);
      // },
      filterPage() {
         if (this.pageNo.length > 0) {
            this.params = `&page=${this.pageNo}`;
            this.fetchData(this.activeTab);
            this.currentPage = this.pageNo;
         }
      },
      // search(event) {
      //    if (this.filter.length > 1) {
      //       if (event.keyCode == 13) {
      //          this.fetchData(this.activeTab);
      //       }
      //    } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      // },
      async fetchData(txt) {
         let url = null;
         if (txt == "trash") {
           this.activeTab = txt;
           if (this.filter != null && this.filter != "") {
             url = member_tag.getMemberTagData + "?filter=" + txt + "&search=" + this.filter;
           } else {
             url = member_tag.getMemberTagData + "?filter=" + txt;
           }
         } else if (txt == "all") {
           this.activeTab = txt;
           if (this.filter != null && this.filter != "") {
             url = member_tag.getMemberTagData + "?filter=" + txt + "&search=" + this.filter;
           } else {
             url = member_tag.getMemberTagData + "?filter=" + txt;
           }
         }
         url += this.params;
         this.$store.commit("loader/updateStatus", true);
         try {
           const data = await this.getRequest(url);
           if (data.status) {
             const responseData = data.response;
             this.member_tag_type = responseData;
           }
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
         this.key += 1;
      },
      async fetchMemberTagType(id) {  
         this.$store.commit("loader/updateStatus", true);
         try {
           let url = member_tag.getMemberTagData + "/" + id;
           const data = await this.getRequest(url);
           if (data.status) {
             const responseData = data.response;
 
             if (responseData.name != null && responseData.name != "") {
                 this.form.name = responseData.name;
             }
 
             if (responseData.color != null && responseData.color != "") {
               this.form.color = responseData.color;
             }

             if (responseData.email_html != null && responseData.email_html != "") {
               this.form.email_html = responseData.email_html;
             }

            this.edit.badge_icon_url = responseData.badge_icon;
            this.badge_icon = responseData.badge_icon;

             if (responseData.is_active) {
               this.form.is_active = 1;
             }
           }
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      tabActive() {
         if (this.activeTab == "trash") {
            if (this.table_header.includes("delete")) {
               let index = this.fields.findIndex((item) => item.key == "delete");
               this.fields[index] = {
                  key: "restore",
               };
               let table_index = this.table_header.findIndex(
                  (item) => item == "delete"
               );
               this.table_header[table_index] = "restore";
            } else {
               this.table_header.push("restore");
               this.fields.push({
                  key: "restore",
               });
            }

            if (!this.can("restore-member-tag-type")) {
               let index = this.fields.findIndex((item) => item.key == "restore");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "restore"
                  );
                  this.table_header.splice(table_index, 1);
               }
            }

            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
               this.fields.splice(index, 1);
               let table_index = this.table_header.findIndex(
                  (item) => item == "edit"
               );
               this.table_header.splice(table_index, 1);
            }
         } else {
            if (this.table_header.includes("restore")) {
               if (!this.can("edit-member-tag-type")) {
                  this.fields[5] = {
                     key: "delete",
                  };
               } else {
                  this.fields[6] = {
                     key: "delete",
                  };
               }

               let table_index = this.table_header.findIndex(
                  (item) => item == "restore"
               );
               this.table_header[table_index] = "delete";
            }

            if (!this.can("edit-member-tag-type")) {
               let index = this.fields.findIndex((item) => item.key == "edit");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "edit"
                  );
                  this.table_header.splice(table_index, 1);
               }
            } else {
               if (!this.table_header.includes("edit")) {
                  this.table_header.push("edit");
                  this.fields[5] = {
                     key: "edit",
                  };
               }
            }
            if (!this.can("delete-member-tag-type")) {
               let index = this.fields.findIndex((item) => item.key == "delete");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "delete"
                  );
                  this.table_header.splice(table_index, 1);
               }
            } else {
               if (!this.table_header.includes("delete")) {
                  this.table_header.push("delete");
                  this.fields[6] = {
                     key: "delete",
                  };
               }
            }
         }
      },
      async submitData() {
         try {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Please Fill The Required Fields"
              });
              return false;
            }
            this.$store.commit("loader/updateStatus", true);
            let url = member_tag.getMemberTagData;
            if (this.$route.name == "edit-member-tag-type") {
               url = member_tag.getMemberTagData + "/" + this.$route.params.id;
            }
            let dataAppend = new FormData();
            if (this.form.is_active) {
               dataAppend.append("is_active", 1);
            } else {
               dataAppend.append("is_active", 0);
            }
            if (typeof this.badge_icon === 'string') {
               if (this.badge_icon.includes("https://")) {
                 this.badge_icon = "";
               }
             }  
            if(this.badge_icon){
               dataAppend.append("badge_icon", this.badge_icon);
             }     
            for (var key in this.form) {
               if (key != "is_active" && key != "badge_icon") {
                  dataAppend.append(key, this.form[key]);
               }
            }
            if (this.$route.name == "edit-member-tag-type") {
               dataAppend.append("_method", "put");
            }
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
               this.$router.push("/member-tag-type");
            }
         } catch (err) {
            console.log(err);
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async deleteMemberTagType(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
           let index = this.member_tag_type.data.findIndex((e) => e.id === id);
           const url = member_tag.getMemberTagData + "/" + id;
           const data = await this.postRequest(url, {
             _method: "DELETE",
           });
           if (data.status) {
             this.member_tag_type.data.splice(index, 1);
             this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "success",
               title: data.message,
             });
           }
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
       },
       async restoreMemberTagType(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
           let index = this.member_tag_type.data.findIndex((e) => e.id === id);
           const url = member_tag.restoreData + "/" + id;
           const data = await this.postRequest(url, {});
           if (data.status) {
             this.member_tag_type.data.splice(index, 1);
             this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "success",
               title: data.message,
             });
           }
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
       },
       async updateStatus(id) {
         try {
           let index = this.member_tag_type.data.findIndex((e) => e.id === id);
           const url = member_tag.updateStatus + "/" + id;
           const data = await this.postRequest(url, {
             is_active: !this.member_tag_type.data[index].is_active,
           });
           if (data.status) {
             const responseData = data.response;
             this.member_tag_type.data[index] = responseData;
             this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "success",
               title: data.message,
             });
           }
           this.key += 1;
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
       },
   },
   watch: {
      currentPage: {
         handler: function (v) {
            if (v != "" && v != null) {
               this.params = `&page=${v}`;
               this.fetchData(this.activeTab);
               this.pageNo = this.currentPage;
            }
         }
      },
      "form.is_active"(v) {
         if (v == 1) {
           this.form.is_active = true;
         } else {
           this.form.is_active = false;
         }
       },
      activeTab(v) {
         if (v) this.tabActive();
      },
   },
   mounted() {
      if (this.$route.name == "member-tag-type") {
         this.fetchData(this.activeTab);
      } else if (this.$route.name == 'edit-member-tag-type') {
         this.fetchMemberTagType(this.$route.params.id); 
      }
      this.fields.map((item) => {
         this.table_header.push(item.key);
      });
      this.tabActive();
   }
}